<template>
	<div>
		<div class="page-content">
			<el-breadcrumb separator=">" style="position: absolute;margin-top: -4px;">
				<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
				<el-breadcrumb-item>Contact</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div>
			<el-image style="margin-top: 40px;width: 100%;height: 300px;"
				:src="require('@/assets/images/Contact-bg.png')"></el-image>
		</div>
		<section class="page-content" style="margin-top: 40px;height: 666px;">
			<div style="border: 1px solid #333333;width: 800px;height: 400px;">
				<h2 style="text-align: center;">If You Have Any Questions, Feel Free To Contact With Us.</h2>
				<el-form :model="form" :rules="rules" ref="form" label-width="120px" style="width: 500px;">
					<el-form-item label="Name" prop="fieldA">
						<el-input v-model="form.fieldA" />
					</el-form-item>
					<el-form-item label="E-mail" required prop="fieldB">
						<el-input v-model="form.fieldB" />
					</el-form-item>
					<el-form-item label="Message" prop="fieldC">
						<el-input v-model="form.fieldC" type="textarea" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitNow('form')"
							style="width: 150px;border: #6b57a5;background-color: #6b57a5;">Submit Now</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div style="float: right;margin-top: -174px;">
				<el-image style="" :src="require('@/assets/images/contact_03.jpg')"></el-image>
			</div>
		</section>
		<section class="page-content" style="margin-bottom: 100px;">
			<div style="text-align: center;font-size: 26px;">
				<h1>
					<el-icon style="color: #FBA921;font-size: 33px;margin-right: 10px;">
						<Sunrise />
					</el-icon>
					<span>Scholarly Boosting&nbsp;</span>
					<span style="color:#6b57a5;">Services</span>
				</h1>
			</div>
			<div>
				<h2>Contact us by email</h2>
				<p>The notifications of Editor's Choice, Digest, Selected Papers or Highly cited papers, were analyzed
					from bibliographic databases and directed to some readers with purpose to spread the contents
					readership issues. If you are interested in getting involved in this movement, please use the form
					below. This will ensure that your message is forwarded to the right person for responding as quickly
					as possible.</p>
			</div>
			<div>
				<el-form :model="scholarlyBoostingForm" :rules="scholarlyBoostingFormRules" ref="scholarlyBoostingForm"
					label-position='top'>
					<el-row :gutter="20">
						<el-col :span="12">
							<el-form-item label="First name" required prop="fieldA">
								<el-input v-model="scholarlyBoostingForm.fieldA" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Last name" required prop="fieldB">
								<el-input v-model="scholarlyBoostingForm.fieldB" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="Email address" required prop="fieldC">
						<el-input v-model="scholarlyBoostingForm.fieldC" />
					</el-form-item>
					<el-form-item label="Institution" required prop="fieldD">
						<el-input v-model="scholarlyBoostingForm.fieldD" />
					</el-form-item>
					<el-form-item label="Your message" required prop="fieldE">
						<el-input v-model="scholarlyBoostingForm.fieldE" type="textarea" :rows="5" />
					</el-form-item>
					<el-row :gutter="20">
						<el-col :span="12">
							<el-form-item label="Telephone" prop="fieldF">
								<el-input v-model="scholarlyBoostingForm.fieldF" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Verification Code" required prop="fieldG">
								<el-input v-model="scholarlyBoostingForm.fieldG" type="text">
									<template #prepend>
										<canvas id="canvas" width="100" height="43" @click="changeCode()"></canvas>
									</template>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item>
						<el-button type="primary" @click="send('scholarlyBoostingForm')"
							style="width: 150px;border: #6b57a5;background-color: #6b57a5;float: right;">SEND
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</section>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			const verificationCode = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('Verification Code is required!'))
				} else {
					if (this.scholarlyBoostingForm.fieldG != this.show_num) {
						callback(new Error("Incorrect verification code!"));
					}
					return callback();
				}
			}
			return {
				show_num: '',
				form: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					classification: 'submitNow'
				},
				rules: {
					fieldB: [{
							required: true,
							trigger: "blur",
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],

				},
				scholarlyBoostingForm: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					fieldG: '',
					classification: 'send'

				},
				scholarlyBoostingFormRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "First name is required!"
					}, ],
					fieldB: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Last name is required!"
					}, ],
					fieldC: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "Email address is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],
					fieldD: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Institution is required!"
					}, ],
					fieldE: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Your message required!"
					}, ],
					fieldG: [{
						validator: verificationCode,
						trigger: ['blur', 'change'],
					}],
				},
			};
		},

		methods: {
			submitNow(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.form, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			send(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.scholarlyBoostingForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			changeCode() {
				this.show_num = ''
				this.draw()
			},
			draw() {
				let _this = this
				var canvas_width = 100;
				var canvas_height = 35;
				var canvas = document.getElementById("canvas"); //获取到canvas的对象，演员
				var context = canvas.getContext("2d"); //获取到canvas画图的环境，演员表演的舞台
				canvas.width = canvas_width;
				canvas.height = canvas_height;
				var sCode = "A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
				var aCode = sCode.split(",");
				var aLength = aCode.length; //获取到数组的长度

				for (var i = 0; i <= 3; i++) {
					var j = Math.floor(Math.random() * aLength); //获取到随机的索引值
					var deg = Math.random() * 30 * Math.PI / 180; //产生0~30之间的随机弧度
					var txt = aCode[j]; //得到随机的一个内容
					// _this.show_num.splice(0, 0, txt.toLowerCase())
					_this.show_num += (txt.toLowerCase())
					var x = 10 + i * 20; //文字在canvas上的x坐标
					var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
					context.font = "bold 23px 微软雅黑";

					context.translate(x, y);
					context.rotate(deg);

					context.fillStyle = _this.randomColor();
					context.fillText(txt, 0, 0);

					context.rotate(-deg);
					context.translate(-x, -y);
				}
				for (var i = 0; i <= 5; i++) { //验证码上显示线条
					context.strokeStyle = _this.randomColor();
					context.beginPath();
					context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
					context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
					context.stroke();
				}
				for (var i = 0; i <= 30; i++) { //验证码上显示小点
					context.strokeStyle = _this.randomColor();
					context.beginPath();
					var x = Math.random() * canvas_width;
					var y = Math.random() * canvas_height;
					context.moveTo(x, y);
					context.lineTo(x + 1, y + 1);
					context.stroke();
				}
			},
			randomColor() { //得到随机的颜色值
				var r = Math.floor(Math.random() * 256);
				var g = Math.floor(Math.random() * 256);
				var b = Math.floor(Math.random() * 256);
				return "rgb(" + r + "," + g + "," + b + ")";
			}
		},
		created() {

		},
		mounted() {
			this.draw();
		},

	};
</script>

<style lang="scss">
	.el-input__inner:focus {
		border-color: rgb(107 87 165);
	}

	.el-textarea__inner:focus {
		border-color: rgb(107 87 165);
	}
</style>
